import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx, keyframes } from '@emotion/react';
import { skeletonShimmer } from '@atlaskit/theme/constants';
var shimmer = skeletonShimmer();
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
var shimmerKeyframes = keyframes(shimmer.keyframes);
var skeletonStyles = css(_objectSpread({}, shimmer.css));
var activeShimmerStyles = css({
  animationName: "".concat(shimmerKeyframes)
});

/**
 * __Skeleton__
 *
 * A skeleton acts as a placeholder for content, usually while the content loads.
 *
 * - [Examples](https://atlassian.design/components/skeleton/examples)
 * - [Code](https://atlassian.design/components/skeleton/code)
 */
var Skeleton = function Skeleton(_ref) {
  var width = _ref.width,
    height = _ref.height,
    _ref$borderRadius = _ref.borderRadius,
    borderRadius = _ref$borderRadius === void 0 ? '100px' : _ref$borderRadius,
    _ref$isShimmering = _ref.isShimmering,
    isShimmering = _ref$isShimmering === void 0 ? false : _ref$isShimmering,
    groupName = _ref.groupName,
    testId = _ref.testId;
  var groupDataAttribute = groupName && "data-".concat(groupName);
  return jsx("div", _extends({
    "data-testid": testId,
    css: [skeletonStyles, isShimmering && activeShimmerStyles,
    // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
    {
      width: width,
      height: height,
      borderRadius: borderRadius
    }]
  }, groupDataAttribute && _defineProperty({}, groupDataAttribute, 'true')));
};
export default Skeleton;