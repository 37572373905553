import { B200, N0, N10, N100, N20, N200, N30, N40, N70, N900, R400 } from '@atlaskit/theme/colors';
export var disabledBackground = "var(--ds-background-disabled, ".concat(N20, ")");
export var disabledBorder = "var(--ds-border-disabled, ".concat(N40, ")");
export var invalidBorderColor = "var(--ds-border-danger, ".concat(R400, ")");
export var defaultBorderColor = "var(--ds-border-input, ".concat(N100, ")");
export var defaultBorderColorFocus = "var(--ds-border-focused, ".concat(B200, ")");
export var defaultBackgroundColor = "var(--ds-background-input, ".concat(N10, ")");
export var defaultBackgroundColorHover = "var(--ds-background-input-hovered, ".concat(N30, ")");
export var defaultBackgroundColorFocus = "var(--ds-background-input-pressed, ".concat(N0, ")");
export var subtleBorderColorHover = "var(--ds-border-input, transparent)";
export var placeholderTextColor = "var(--ds-text-subtlest, ".concat(N200, ")");
export var textColor = "var(--ds-text, ".concat(N900, ")");
export var disabledTextColor = "var(--ds-text-disabled, ".concat(N70, ")");
export var transparent = 'transparent';