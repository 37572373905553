import { ROUTE_NAME, RouteFeatures, SupportedPath } from '../types';

export const getSupportedPaths = (features: RouteFeatures): SupportedPath[] => {
  const { isAccountSettingsIndexEnabled } = features;
  const paths = [];

  if (isAccountSettingsIndexEnabled) {
    paths.push(
      {
        name: ROUTE_NAME.ACCOUNT_SETTINGS_IFRAME,
        path: '/account/settings/',
        exact: true,
      },
      {
        name: ROUTE_NAME.ACCOUNT_SETTINGS_IFRAME,
        path: '/account/settings/:any',
        exact: false,
      }
    );
  }

  return paths;
};
