/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import Button from '@atlaskit/button/custom-theme-button';
import { Text } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
var tooltipMessage = ['Strongly disagree', 'Disagree', 'Slightly disagree', 'Neutral', 'Slightly agree', 'Agree', 'Strongly agree'];
export default (function (_ref) {
  var onChange = _ref.onChange,
    value = _ref.value;
  return jsx("div", null, jsx("div", {
    // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css: css({
      display: 'flex',
      justifyContent: 'space-between',
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
      '& > * + *': {
        marginLeft: "var(--ds-space-100, 8px)"
      },
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
      '& > *': {
        flex: 1,
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
        '& > button': {
          justifyContent: 'center'
        }
      }
    })
  }, Array.from({
    length: 7
  }, function (_, i) {
    var score = i + 1;
    var isSelected = value === score;
    return jsx(Tooltip, {
      content: tooltipMessage[i],
      key: score,
      hideTooltipOnClick: true
    }, jsx(Button, {
      onClick: function onClick() {
        return onChange(score);
      },
      isSelected: isSelected,
      "aria-pressed": isSelected,
      "aria-describedby": "contextualSurveyStatement",
      "aria-label": tooltipMessage[i],
      shouldFitContainer: true
    }, score));
  })), jsx("div", {
    // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css: css({
      display: 'flex',
      marginTop: "var(--ds-space-100, 8px)",
      marginBottom: "var(--ds-space-300, 24px)",
      justifyContent: 'space-between',
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
      '& > span': {
        width: "var(--ds-space-1000, 80px)"
      }
    }),
    "aria-hidden": true
  }, jsx(Text, {
    color: "color.text.subtlest",
    size: "UNSAFE_small",
    weight: "semibold",
    align: "start"
  }, "Strongly disagree"), jsx(Text, {
    color: "color.text.subtlest",
    size: "UNSAFE_small",
    weight: "semibold",
    align: "center"
  }, "Neutral"), jsx(Text, {
    color: "color.text.subtlest",
    size: "UNSAFE_small",
    weight: "semibold",
    align: "end"
  }, "Strongly agree")));
});