/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { Transition } from 'react-transition-group';
import { layers } from '@atlaskit/theme/constants';
import { surveyInnerWidth, surveyOffset } from '../constants';
var animationDuration = 300;
var offscreen = {
  translateX: "".concat(surveyInnerWidth + surveyOffset, "px"),
  opacity: '0'
};
var getAnimationProps = function getAnimationProps(state) {
  switch (state) {
    case 'entered':
      {
        return {
          translateX: '0',
          opacity: '1'
        };
      }
    case 'entering':
    case 'unmounted':
    case 'exited':
    case 'exiting':
      {
        return offscreen;
      }
  }
};
export default function SurveyMarshal(props) {
  var children = props.children,
    shouldShow = props.shouldShow;
  return jsx(Transition, {
    in: shouldShow,
    timeout: animationDuration,
    unmountOnExit: true
  }, function (state) {
    var _getAnimationProps = getAnimationProps(state),
      translateX = _getAnimationProps.translateX,
      opacity = _getAnimationProps.opacity;
    return jsx("div", {
      // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
      css: css({
        position: 'fixed',
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        right: "".concat(surveyOffset, "px"),
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        bottom: "".concat(surveyOffset, "px"),
        zIndex: layers.flag(),
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        transform: "translateX(".concat(translateX, ")"),
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
        opacity: opacity,
        transition: "all ".concat(animationDuration, "ms ease-in-out"),
        transitionProperty: 'transform, opacity'
      })
    }, children());
  });
}