/**
 * Flags that pipelines need to create in statsig.
 * Follow https://hello.atlassian.net/wiki/spaces/BB/pages/2990998399/Pipelines+UI+-+Using+Feature+Flags to create new flags in statsig
 * No New flags should be added in this file
 */
export const pipelinesLDFeatureKeys = {
  // to be migrated long-living flags or unreleased features
  pipelinesArtifactPreviewSizeLimit: 'pipelines-artifact-preview-size-limit',
  pipelinesMaximumAllowedStepSizeForCloudRunners:
    'pipelines-maximum-allowed-step-size-for-cloud-runners',
  isPipelinesManualStepsInStagesEnabled:
    'pipelines-allow-manual-steps-in-stages',
  logViewerDarkModeEnabled: 'bitbucket-pipelines-log-viewer-dark-mode-enabled',
  pipelinesEditorDarkModeEnabled:
    'bitbucket-pipelines-editor-dark-mode-enabled',
  isStepMetricsEnabled: 'pipelines-step-metrics-enabled',
  isPipelinesStickyEnvParamEnabled: 'pipelines-sticky-env-parameter',
  isPipelineListSidebarEnabled: 'enable-pipeline-list-sidebar',
  isPipelineRunsHistoryEnabled: 'enable-pipeline-runs-history',
  updatedStepList: 'updated-pipelines-step-list',
  enableStashCommitLink: 'pipelines-add-stash-commit-link',
  showShadowRepositoryConfiguration: 'show-shadow-repository-configuration',

  // need to be checked - half released or released but with certain segments turned off
  isDeploymentsFilteringEnabled: 'deployments-filtering', // check with Liam(Peter is no longer with pipelines)
  isPipelinesMacOSTartRunnerEnabled: 'pipelines-mac-os-tart-runner', // check with Liam(Allan has left)
  // check with Ally
  isPipelineTypeHandleSpecialChars:
    'pipelines-pipeline-type-filter-handle-special-chars',
  enableAddingVariablesFix: 'adding-variables-in-editor-fix', // check with Ally
  enableCustomCronSupport: 'bitbucket-pipelines-custom-cron-pattern-support', // check with Ally
  enablePersistPipelineListFilters: 'pipelines-persist-pipeline-list-filters', // check with Ally

  // to be removed - 100% released or be 100% in few days
  pipelinesMaxStepDuration: 'pipelines-max-step-duration',
  enableRunPipelineFromBranchPage: 'run-pipelines-from-single-branch-page',
  isPipelinesEditorInFrontbucketEnabled: 'pipelines-editor-ui-in-frontbucket',
  isPipelinesSettingsInFrontbucketEnabled:
    'pipelines-settings-ui-in-frontbucket',
  isPipelinesRunnersSettingsInFrontbucketEnabled:
    'pipelines-runners-settings-ui-in-frontbucket',
  incrementalRequestEnabled: 'bitbucket-pipelines-incremental-log-request',
  isSagaLinkifyDisabled: 'bitbucket-pipelines-disable-saga-linkify',
  isPipelinesDeploymentsSettingsInFrontbucketEnabled:
    'pipelines-deployments-settings-ui-in-frontbucket',
  isPipelinesSecuredPrefixedVariablesEnabled:
    'pipelines-secured-prefixed-variables',
  isPipelinesRunnerUuidDisplayEnabled:
    'pipelines-enable-runner-table-uuid-display',
  isRunPipelineModalInFrontbucket:
    'pipelines-run-pipeline-modal-in-frontbucket',
  enableSendingDefaulVariableValues:
    'enable-sending-default-variables-for-run-pipeline-modal',
  useUpdatedStepDurationFormat: 'pipelines-update-stage-step-duration-format',
  enableImprovedErrorMapping: 'pipelines-improved-error-mapping',
  isDescriptionEnabled: 'bitbucket-pipelines-variable-description',
  logLineWrapped: 'platform.pipelines.log-line-wrapped',
  enableHandleAllowanceResponse: 'handle-allowance-returning-404',
};
