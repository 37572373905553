import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import Heading from '@atlaskit/heading';
import { Stack, Text } from '@atlaskit/primitives';
import SuccessContainer from './SuccessContainer';
export default (function (_ref) {
  _objectDestructuringEmpty(_ref);
  return jsx(SuccessContainer, null, jsx(Stack, {
    space: "space.150"
  }, jsx(Heading, {
    size: "xsmall"
  }, "Thanks for signing up"), jsx(Text, {
    as: "p"
  }, "We may reach out to you in the future to participate in additional research.")));
});