import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import flatten from 'lodash/flatten';
import isUndefined from 'lodash/isUndefined';
import last from 'lodash/last';
import merge from 'lodash/merge';
import omitBy from 'lodash/omitBy';
import uniq from 'lodash/uniq';
var extractFromContext = function extractFromContext(event, property) {
  return event.context.map(function (item) {
    return item[property];
  });
};
export var getAnalyticsType = function getAnalyticsType(event) {
  return event.payload.analyticsType;
};
export var getAttributes = function getAttributes(event) {
  var contextAttributes = extractFromContext(event, 'attributes');
  var payloadAttributes = event.payload.attributes;
  var allAttributes = merge(contextAttributes.reduce(function (result, extraAttributes) {
    return merge(result, extraAttributes);
  }, {}), payloadAttributes);
  var sources = extractFromContext(event, 'source');
  var namespaces = sources.filter(Boolean).join('.');
  return omitBy(_objectSpread(_objectSpread({}, allAttributes), {}, {
    namespaces: namespaces
  }), isUndefined);
};
export var getSource = function getSource(event) {
  return last(extractFromContext(event, 'source').filter(Boolean));
};
export var getActionSubject = function getActionSubject(event) {
  var actionSubject = event.payload.actionSubject;
  if (actionSubject) {
    return actionSubject;
  }
  var component = last(extractFromContext(event, 'component').filter(Boolean)) || last(extractFromContext(event, 'componentName').filter(Boolean));
  return component;
};
export var getAction = function getAction(event) {
  return event.payload.action;
};
export var getActionSubjectId = function getActionSubjectId(event) {
  return event.payload.actionSubjectId;
};
export var getContainerId = function getContainerId(event) {
  return last(extractFromContext(event, 'containerId').filter(Boolean));
};
export var getContainerType = function getContainerType(event) {
  return last(extractFromContext(event, 'containerType').filter(Boolean));
};
export var getObjectId = function getObjectId(event) {
  return last(extractFromContext(event, 'objectId').filter(Boolean));
};
export var getObjectType = function getObjectType(event) {
  return last(extractFromContext(event, 'objectType').filter(Boolean));
};
export var getTags = function getTags(event) {
  var tags = uniq(flatten(extractFromContext(event, 'tags'))).filter(Boolean);
  return tags.length ? tags : undefined;
};