import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import { OPERATIONAL_EVENT_TYPE, SCREEN_EVENT_TYPE, TRACK_EVENT_TYPE, UI_EVENT_TYPE } from '../../constants';
import { getAction, getActionSubject, getActionSubjectId, getAnalyticsType, getAttributes, getContainerId, getContainerType, getObjectId, getObjectType, getSource, getTags } from './utils';
export var extractAWCDataFromEvent = function extractAWCDataFromEvent(event) {
  // TODO: the distinction between AnalyticsEvent and UIAnalyticsEvent is all odd in AK and needs to be understood better
  var castedEvent = event;
  var analyticsType = getAnalyticsType(castedEvent);
  var attributes = getAttributes(castedEvent);
  var source = getSource(castedEvent);
  var actionSubject = getActionSubject(castedEvent);
  if (analyticsType === SCREEN_EVENT_TYPE) {
    return {
      type: SCREEN_EVENT_TYPE,
      payload: {
        name: source,
        attributes: attributes
      }
    };
  }
  switch (analyticsType) {
    case UI_EVENT_TYPE:
    case TRACK_EVENT_TYPE:
    case OPERATIONAL_EVENT_TYPE:
      {
        var action = getAction(castedEvent);
        var actionSubjectId = getActionSubjectId(castedEvent);
        var containerId = getContainerId(castedEvent);
        var containerType = getContainerType(castedEvent);
        var objectId = getObjectId(castedEvent);
        var objectType = getObjectType(castedEvent);
        var tags = getTags(castedEvent);
        return {
          type: analyticsType,
          payload: omitBy({
            action: action,
            actionSubject: actionSubject,
            actionSubjectId: actionSubjectId,
            source: source,
            tags: tags,
            containerType: containerType,
            containerId: containerId,
            objectType: objectType,
            objectId: objectId,
            attributes: attributes
          }, isUndefined)
        };
      }
    default:
      {
        return {
          payload: {
            source: source,
            actionSubject: actionSubject,
            attributes: attributes
          },
          type: undefined
        };
      }
  }
};